import React, { useEffect, useRef, useState, useContext } from "react";
import { Typography, Box, CssBaseline, AppBar, Toolbar, Tooltip } from "@mui/material";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { AuthContext } from '../../context/AuthContext';
import Peer from "simple-peer";
import io from "socket.io-client";
import process from 'process';
import "./Stream.css";
import BackOfficeView from "../../views/stream/BackOffice.view";
import TotemView from "../../views/stream/Totem.view";
import CopyrightFooter from "../Shared/CopyrightFooter";

global.process = process;

function Stream() {
  const { user, logout } = useContext(AuthContext);
  const socketRef = useRef();
  const [me, setMe] = useState("");
  const [stream, setStream] = useState();
  const [userStream, setUserStream] = useState();
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [startCall, setStartCall] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");
  const [workStationList, setWorkStationList] = useState([]);
  const [connectedUserName, setConnectedUserName] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [openMicrophone, setOpenMicrophone] = useState(false);
  const [userOpenCamera, setUserOpenCamera] = useState(false);
  const [userOpenMicrophone, setUserOpenMicrophone] = useState(false);
  const [requestAssistance, setRequestAssistance] = useState(false);
  const [assistanceName, setAssistanceName] = useState("");
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  const { type: role, id: user_id, name: user_name } = user;
  const user_type = role;

  useEffect(() => {
    if (stream && role === "admin") {
      stream.getTracks().forEach(track => {
        if (track.kind === "video") {
          track.enabled = openCamera;
        }
        if (track.kind === "audio") {
          track.enabled = openMicrophone;
        }
      });
    }
  }, [openCamera, openMicrophone, stream, role]);

  useEffect(() => {
    socketRef.current = io.connect(process.env.REACT_APP_STREAMING_API, {
      extraHeaders: {
        "user_id": user_id,
        "user_type": user_type
      }
    });

    navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          setStream(stream);
        });

    socketRef.current.on("me", (id) => {
      setMe(id);
    });

    socketRef.current.on("connectedUserName", (userName) => {
      setConnectedUserName(userName);
    });

    socketRef.current.on("updateAvailableConnections", availableConnections => {
      setWorkStationList(availableConnections);
    });

    socketRef.current.on("setUserOpenCamera", (openCamera) => {
      setUserOpenCamera(openCamera);
    });

    socketRef.current.on("setUserOpenMicrophone", (openMicrophone) => {
        setUserOpenMicrophone(openMicrophone);
    });

    socketRef.current.on("callUser", (data) => {
      setCaller(data.from);
      setName(data.name);
      setCallerSignal(data.signal);
      setStartCall(true);
      setCallEnded(false);
    });

    socketRef.current.on("requestAssistance", (data) => {
      setAssistanceName(data.user_name);
      setRequestAssistance(true);
    });

    if (user_id) {
        return () => {
          socketRef.current.disconnect();
        }
    }
  }, [user_id, user_type]);

  useEffect(() => {
    socketRef.current.on("callEnded", () => {
      if (connectionRef.current) {
        connectionRef.current.destroy();
        setCallEnded(true);
        setCallAccepted(false);
        setUserOpenMicrophone(false);
        setUserOpenCamera(false);
      }
    });
  }, []);

  useEffect(() => {
    if (me) {
      socketRef.current.emit("getConnectedUserName", me);
    }
  }, [workStationList, me]);

  useEffect(() => {
    if (startCall) {
      setCallAccepted(true);
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: stream,
      });
      peer.on("signal", (data) => {
        socketRef.current.emit("answerCall", { signal: data, to: caller });
      });
      peer.on("stream", (stream) => {
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
          userVideo.current.volume = 1;
          setUserStream(stream);
        }
      });

      peer.signal(callerSignal);
      connectionRef.current = peer;

      peer.on("error", (err) => {
        console.error('Peer error:', err);
      });

      return () => {
        if (connectionRef.current) {
          connectionRef.current.destroy();
        }
      };
    }
  }, [startCall, callerSignal, caller, stream]);

  useEffect(() => {
    if (myVideo.current && stream) {
      myVideo.current.srcObject = stream;
      myVideo.current.volume = 1;
    }
  }, [myVideo, stream]);

  const callUser = (id) => {
    if (id) {
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream,
      });
      peer.on("signal", (data) => {
        socketRef.current.emit("callUser", {
          userToCall: id,
          signalData: data,
          from: me,
          name: name,
        });
      });
      peer.on("stream", (stream) => {
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
          userVideo.current.volume = 1;
          setUserStream(stream);
        }
      });
      socketRef.current.on("callAccepted", (signal) => {
        socketRef.current.off("callAccepted");
        setCallAccepted(true);
        peer.signal(signal);
        socketRef.current.emit(
            "updateAvailableConnections",
            { connection_id: me, connected_connection_id: id }
        );
      });

      connectionRef.current = peer;

      peer.on("error", (err) => {
        console.error('Peer error:', err);
      });
    } else {
      alert("Por favor selecciona un dispositivo");
    }
  };

  const toggleCamera = (idToCall) => {
    setOpenCamera(!openCamera);

    if (callAccepted && idToCall) {
      socketRef.current.emit("setUserOpenCamera", { idToCall, openCamera: !openCamera });
    }
  };

  const toggleMicrophone = (idToCall) => {
    setOpenMicrophone(!openMicrophone);

    if (callAccepted && idToCall) {
        socketRef.current.emit("setUserOpenMicrophone", { idToCall, openMicrophone: !openMicrophone });
    }
  };

  const leaveCall = (idToCall) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    if (connectionRef.current) {
      try {
        connectionRef.current.removeAllListeners();
        connectionRef.current.destroy();
        connectionRef.current = null;
      } catch (err) {
        console.error('Error destroying peer:', err);
      }
    }
    setCallEnded(true);
    socketRef.current.emit("callEnded", idToCall);
    logout();
  };

  const handleRequestAssistance = () => {
    const identifier = {
      connection_id: me,
      connected_connection_id: caller,
      user_name
    };
    socketRef.current.emit("requestAssistance", identifier);
  };

  return (
      <div>
        <CssBaseline/>
        <AppBar position="fixed" sx={{backgroundColor: "#fe0000"}}>
          <Toolbar>
            <Box sx={{flexGrow: 1, display: "flex", justifyContent: "flex-start"}}>
              <img src="./santander_rojo.png" alt="Logo" style={{height: 40}}/>
            </Box>
            {(role === "user" && callAccepted) && (
                <Tooltip title={`En vigilancia activa por ${connectedUserName}`}>
                  <Box style={{ marginRight: '5px' }}>
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/5cc13039-969b-4a51-ae14-4a80c8507d62/IvhSjPC5Ex.json"
                        style={{ height: '40px', width: '40px' }}
                    >
                      <Controls visible={false} />
                    </Player>
                  </Box>
                </Tooltip>
            )}
            <Box>
              <Typography variant="h5">Guardia Virtual</Typography>
            </Box>
            <Box sx={{flexGrow: 1, display: "flex", justifyContent: "center"}}>
              <div className="logout-container" onClick={logout}>
                <Typography variant="body1">Cerrar Sesión</Typography>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        {role === "admin"
            ? <BackOfficeView
                callbacks={{leaveCall, callUser, toggleCamera, toggleMicrophone}}
                state={{stream, userStream, callAccepted, callEnded, idToCall, workStationList, user, openCamera, openMicrophone, requestAssistance, assistanceName}}
                setState={{setCallAccepted, setIdToCall, setRequestAssistance, setAssistanceName}}
                refs={{myVideo, userVideo}}
            />
            : <TotemView
                setState={{setCallAccepted}}
                state={{
                  stream,
                  callAccepted,
                  callEnded,
                  workStationList,
                  caller,
                  user,
                  connectedUserName,
                  userOpenCamera,
                  userOpenMicrophone,
                  requestAssistance
                }}
                refs={{myVideo, userVideo}}
                callbacks={{handleRequestAssistance}}
            />
        }
        <CopyrightFooter sx={{ pt: 4 }} />
      </div>
  );
}

export default Stream;